<template>
  <div class="log-wrap" :class="{ full: !expandNav }">
    <TopNav
      v-if="expandNav"
      :title="'left_bar_log'"
      :navList="navList"
      v-model="tabView"
      @goTo="switchTab"
    />
    <div class="log-sub">
      <transition>
        <router-view :expand="expandNav" @expand="onToggleExpand" />
      </transition>
    </div>
    <AllowAudio v-if="showAllowAudio" @closemodal="closeAllowAudioModal" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { routeGo, logPermission } from '@/router'

export default {
  name: 'Log',
  components: {
    TopNav: () => import(`@/components/tools/TopNav.vue`),
    AllowAudio: () => import('@/components/AllowAudio.vue'),
  },
  data() {
    return {
      expandNav: true,

      tabView: null
    }
  },
  computed: {
    ...mapState(['permissionV2', 'showAllowAudio']),
    navList() {
      const {
        access,
        // resource
      } = logPermission(this.permissionV2)
      // console.log(`[navList] access:`, access)
      const list = []

      if (access) {
        list.push({
          navTitle: 'log_access'/*存取日誌*/,
          comp: 'AccessLog',
          // icon: require('@/assets/icons/LPR.svg'),
          accessFlag: 'audit'
        })
      }
      // if (resource) {
      //   list.push({
      //     navTitle: 'log_resource'/*資源日誌*/,
      //     comp: 'ResourceLog',
      //     icon: require('@/assets/icons/LPR.svg'),
      //     // accessFlag: 'audit'
      //   })
      // }

      // console.log(`[navList] list:`, list)
      return list
    }
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler() {
        const tab = this.navList.find((_t) => {
          const key = String(_t.comp).toLowerCase()
          const path = this.$route.path.split('/').pop().toLowerCase()

          return path.includes(key)
        })

        this.tabView = tab ? tab.comp : null
      }
    }
  },
  methods: {
    ...mapMutations([]),
    ...mapActions('account', ['getUserTreeList', 'getDeviceTreeList']),
    async init() {
      // 取得私有 使用者/設備
      this.getUserTreeList({ public: 0 })
      this.getDeviceTreeList({ public: 0 })
    },
    closeAllowAudioModal() {
      this.$store.commit('updateShowAllowAudio', false) // 之後不需再顯示跳窗詢問
    },
    switchTab(comp) {
      if (this.tabView !== comp) {
        this.tabView = comp
        routeGo(`/log/${comp.charAt(0).toLowerCase()}${comp.slice(1)}`)
      }
    },
    onToggleExpand() {
      this.expandNav = !this.expandNav
    }
  },
  mounted() {
    if (this.navList.length === 0) {
      this.tabView = null
      return
    }
    this.init()
  },
  beforeDestroy() {},
}
</script>

<style lang="scss" scoped>
.log-wrap {
  width: 100%;
  height: 100vh;
  background-color: $color_151B35;
}
</style>
